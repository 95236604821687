import React from "react";

import styles from "./FinancialOpsIcon.module.css";

export const FinancialOpsIcon = (): JSX.Element => (
  <div className={styles.financialOps}>
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="15.5722"
        stroke="black"
        strokeWidth="2"
        width="9.4601"
        x="18.3174"
        y="52.4092"
      />
      <rect
        height="25.5042"
        stroke="black"
        strokeWidth="2"
        width="9.4601"
        x="35.1255"
        y="42.4773"
      />
      <rect
        height="37.7284"
        stroke="black"
        strokeWidth="2"
        width="9.4601"
        x="51.9336"
        y="30.2532"
      />
      <path
        d="M12.6925 38.3207L57.7285 12.3192M57.7285 12.3192L49.4912 10.112M57.7285 12.3192L55.5213 20.5564"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        className={styles.stroke}
        height="78"
        rx="9"
        stroke="black"
        strokeDasharray="2 8"
        strokeWidth="2"
        width="78"
        x="1"
        y="1"
      />
    </svg>
  </div>
);
