export const NotificationBanner = ({
  dismissBanner,
}: Readonly<{
  dismissBanner: () => void;
}>): JSX.Element => (
  <div className="relative bg-black">
    <div className="max-w-screen-2xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div className="pr-16 sm:text-center sm:px-16">
        <p className="text-white text-xs">
          Tillful Selects Highnote to Launch First-of-its-Kind Business Credit
          Building Card{" "}
          <a
            className="underline hover:text-gray-300 transform duration-500 ease-in-out"
            href="https://www.pymnts.com/credit-cards/2022/tillful-launches-card-for-small-businesses-with-highnote-and-mastercard/"
            rel="noreferrer"
            target="_blank"
          >
            Read Press Article <span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </div>
      <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
        <button
          className="flex p-1 rounded-md hover:bg-white hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-white"
          onClick={dismissBanner}
          type="button"
        >
          <span className="sr-only">Dismiss</span>
          {/* Heroicon name: outline/x */}
          <svg
            aria-hidden="true"
            className="h-6 w-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6l12 12"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
);
