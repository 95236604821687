import { ArrowButton } from "@bay1/ui";
import Link from "next/link";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FooterCTA = (): JSX.Element => (
  <section className="bg-gradient-to-br from-green to-yellow px-4 py-14 lg:py-28 lg:px-10">
    <div className="container items-center justify-between lg:flex mx-auto relative max-w-7xl">
      <div className="lg:w-2/3">
        <div className="mx-auto space-y-10">
          <h2 className="text-2xl lg:text-5xl font-display">
            Get Started with Highnote
          </h2>
        </div>
      </div>
      <div className="lg:w-1/3 lg:text-right">
        <Link href="/request-demo">
          <a className="button-large button-transparent lg:mt-2 mt-10">
            Request Demo <ArrowButton isPrimary />
          </a>
        </Link>
      </div>
    </div>
  </section>
);
