import React from "react";

import styles from "./PlatformHeroGraphic.module.css";

export const PlatformHeroGraphic = (): JSX.Element => (
  <div className={styles.completeGraphic}>
    <svg
      fill="none"
      height="410"
      viewBox="0 0 396 410"
      width="396"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="bigGoo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            result="goo"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 40 -5"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
        <filter id="smallGoo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            result="goo"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 40 -5"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
      <path
        className={styles.stroke}
        d="M310.502 213.45H367.619M310.502 213.45C310.502 188.887 301.262 166.492 286.083 149.579M310.502 213.45C310.502 239.515 300.097 263.14 283.231 280.362M215.484 10.7886V118.019C243.504 118.019 268.692 130.201 286.083 149.579M215.484 406.875V308.88C242.008 308.88 265.994 297.965 283.231 280.362M286.083 149.579L355.342 79.1162M283.231 280.362L319.577 311.857"
        stroke="black"
        strokeDasharray="2 8"
        strokeWidth="2"
      />
      <g>
        <circle
          cx="212.381"
          cy="213.201"
          fill="url(#paint0_linear)"
          r="64.1473"
          transform="rotate(45 212.381 213.201)"
        />
        <path
          d="M218.137 207.966C221.31 211.139 221.31 216.283 218.137 219.456C214.964 222.629 209.82 222.629 206.647 219.456C203.474 216.283 203.474 211.139 206.647 207.966C209.82 204.793 214.964 204.793 218.137 207.966Z"
          fill="black"
        />
        <path
          d="M243.591 221.882C245.756 221.949 247.891 221.116 249.543 219.464C252.713 216.294 252.713 211.148 249.54 207.975C246.37 204.805 241.224 204.805 238.054 207.975C236.402 209.627 235.488 211.883 235.633 214.018C235.741 215.994 235.302 217.977 233.818 219.46C232.335 220.944 230.298 221.207 228.244 221.271C226.29 221.196 224.441 221.867 222.892 223.18C222.712 223.332 222.536 223.492 222.366 223.662C222.36 223.668 222.354 223.675 222.348 223.681C222.343 223.686 222.338 223.691 222.333 223.696C221.903 224.125 221.532 224.591 221.219 225.082C220.307 226.495 219.841 228.131 219.945 229.705C220.053 231.681 219.615 233.664 218.131 235.148C216.647 236.632 214.61 236.895 212.556 236.959C210.361 236.875 208.297 237.731 206.645 239.384C203.475 242.554 203.475 247.7 206.652 250.87C209.822 254.039 214.964 254.043 218.137 250.87C219.79 249.217 220.643 246.981 220.545 244.826C220.528 242.975 220.886 240.864 222.37 239.38C223.853 237.897 225.708 237.526 227.816 237.569L227.903 237.569C229.601 237.622 231.281 237.121 232.723 236.106C233.11 235.836 233.48 235.528 233.825 235.182C233.831 235.176 233.837 235.17 233.842 235.165C233.847 235.16 233.851 235.156 233.855 235.152C234.687 234.32 235.301 233.353 235.696 232.322C236.096 231.295 236.281 230.207 236.233 229.139C236.216 227.287 236.574 225.176 238.057 223.693C239.541 222.209 241.396 221.838 243.503 221.882L243.591 221.882Z"
          fill="black"
        />
        <path
          d="M212.175 190.466C214.34 190.533 216.475 189.7 218.127 188.048C221.297 184.878 221.297 179.732 218.124 176.559C214.954 173.389 209.808 173.389 206.638 176.559C204.986 178.211 204.072 180.467 204.217 182.602C204.325 184.578 203.886 186.561 202.402 188.044C200.919 189.528 198.882 189.791 196.828 189.855C194.874 189.78 193.025 190.451 191.476 191.764C191.296 191.916 191.12 192.076 190.95 192.246C190.944 192.253 190.938 192.259 190.932 192.265C190.927 192.27 190.922 192.275 190.917 192.28C190.487 192.709 190.116 193.175 189.803 193.666C188.891 195.079 188.425 196.715 188.529 198.289C188.637 200.266 188.198 202.248 186.715 203.732C185.231 205.216 183.194 205.479 181.14 205.543C178.945 205.459 176.881 206.315 175.229 207.968C172.059 211.138 172.059 216.284 175.236 219.454C178.405 222.623 183.548 222.627 186.721 219.454C188.374 217.801 189.227 215.565 189.129 213.411C189.112 211.559 189.47 209.448 190.954 207.964C192.437 206.481 194.292 206.11 196.4 206.153L196.487 206.153C198.185 206.206 199.865 205.705 201.307 204.69C201.694 204.42 202.063 204.112 202.409 203.766C202.415 203.76 202.421 203.754 202.426 203.749C202.431 203.744 202.435 203.74 202.439 203.736C203.271 202.904 203.885 201.937 204.28 200.906C204.68 199.879 204.865 198.791 204.817 197.723C204.8 195.871 205.158 193.76 206.641 192.277C208.125 190.793 209.98 190.422 212.087 190.466H212.175Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="212.434"
          x2="201.009"
          y1="149.054"
          y2="352.982"
        >
          <stop offset="0.197917" stopColor="#55F5A3" />
          <stop offset="0.932292" stopColor="#DBFF00" stopOpacity="0.21" />
        </linearGradient>
      </defs>
    </svg>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition1}>
        <div className={styles.smallCircle1} />
        <div className={styles.smallCircle1} />
      </div>
    </div>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition2}>
        <div className={styles.smallCircle2} />
        <div className={styles.smallCircle2} />
      </div>
    </div>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition3}>
        <div className={styles.smallCircle3} />
        <div className={styles.smallCircle3} />
      </div>
    </div>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition4}>
        <div className={styles.smallCircle4} />
        <div className={styles.smallCircle5} />
      </div>
    </div>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition5}>
        <div className={styles.smallCircle6} />
        <div className={styles.smallCircle7} />
      </div>
    </div>
    <div className={styles.smallBlobGroup}>
      <div className={styles.smallBlobPosition6}>
        <div className={styles.smallCircle8} />
        <div className={styles.smallCircle9} />
      </div>
    </div>
    <div className={styles.blueCircleSm} />
    <div className={styles.blueCircleMd} />
    <div className={styles.redCircleSm} />
    <div className={styles.redCircleMd} />
    <div className={styles.redCircleLg} />
    <div className={styles.greenCircleSm} />
    <div className={styles.greenCircleMd} />
    <div className={styles.greenCircleLg} />
    <div className={styles.whiteCircleSm} />
    <div className={styles.whiteCircleMd} />
    <div className={styles.whiteCircleLg} />
    <div className={styles.outlineCircleSm} />
    <div className={styles.outlineCircleMd} />
  </div>
);
