/* eslint-disable import/no-unused-modules */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { CommonAppContext } from "@bay1/data";

import { MARKETING_URL } from "../urlHelper";
import { MobileNavPopover } from "../MobileNavPopover";

// eslint-disable-next-line import/order
import { Hamburger } from "./nav/Hamburger";

import { NotificationBanner } from "./NotificationBanner";
import { NavBarLayout } from "./nav/NavBarLayout";
import { NavContainer } from "./nav/NavContainer";
import { AccountMobileNav } from "./AccountNav";

interface MarketingNavProperties {
  backgroundClass?: string;
}

const NAV_ITEMS = [
  ["why-highnote", "Why Highnote"],
  ["platform", "Platform"],
  ["blog", "Resources"],
  ["about", "Company"],
];

export const MarketingDesktopNav = (): JSX.Element => (
  <nav className="hidden lg:flex xl:space-x-8 lg:space-x-6">
    {NAV_ITEMS.map(([path, linkContent]) => (
      <Link href={`${MARKETING_URL}/${path}`} key={path}>
        <a
          className="text-sm border-b-2 border-transparent hover:border-black transform duration-200 ease-in-out"
          data-testid={`marketingNav::${path}`}
        >
          {linkContent}
        </a>
      </Link>
    ))}
  </nav>
);

export const MarketingMobileNav = (): JSX.Element => (
  <nav className="grid grid-cols-1 gap-1">
    {NAV_ITEMS.map(([path, linkContent]) => (
      <Link href={`${MARKETING_URL}/${path}`} key={path}>
        <a
          className="p-3 flex items-center rounded-lg hover:bg-gray-50 transform duration-200 ease-in-out"
          data-testid={`marketingNav::${path}`}
        >
          <div className="ml-4 text-base font-medium">{linkContent}</div>
        </a>
      </Link>
    ))}
  </nav>
);

export const MarketingNav = ({
  backgroundClass,
}: Readonly<MarketingNavProperties>): JSX.Element => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { localStore } = useContext(CommonAppContext);

  const [isDismissed, setIsDismissed] = useState<string | null | undefined>("");
  const toggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback((): void => {
    if (isOpen && router.pathname.includes("/docs")) {
      setIsOpen(false);
    }
  }, [isOpen, router.pathname]);

  const dismissBanner = useCallback((): void => {
    localStore?.setIsBannerDismissed();
    setIsDismissed(localStore?.getIsBannerDismissed());
  }, [localStore]);

  useEffect(() => {
    setIsDismissed(localStore?.getIsBannerDismissed());
  }, [localStore]);

  return (
    <NavContainer backgroundClass={backgroundClass} onClick={handleClose}>
      {isDismissed !== "true" && router.pathname === "/" && (
        <NotificationBanner dismissBanner={dismissBanner} />
      )}

      <div className="relative max-w-screen-2xl mx-auto">
        <NavBarLayout>
          <MarketingDesktopNav />
        </NavBarLayout>
        <Hamburger onClick={toggleIsOpen} />
      </div>
      <MobileNavPopover isOpen={isOpen} onClick={toggleIsOpen}>
        <MarketingMobileNav />
        <AccountMobileNav />
      </MobileNavPopover>
    </NavContainer>
  );
};
